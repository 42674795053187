import Swiper, { Navigation, Pagination } from 'swiper';

export default function InitContactBox() {
    const contactBoxesSections = document.querySelectorAll('.contact-box');

    if (contactBoxesSections && contactBoxesSections.length > 0) {
        Swiper.use([Pagination, Navigation]);

        let slidersArray = [];
        contactBoxesSections.forEach((contactBox, i) => {
            let init = false;

            const section = contactBox.querySelector(
                '.contact-box__contacts-list'
            );
            const prevEl = contactBox.querySelector(
                '.contact-box__arrow-desktop--prev'
            );
            const nextEl = contactBox.querySelector(
                '.contact-box__arrow-desktop--next'
            );

            if (!init) {
                init = true;
                if (section.querySelector('.swiper-wrapper')) {
                    const carousel = new Swiper(section, {
                        slideActiveClass: 'is-active',
                        breakpoints: {
                            0: {
                                slidesPerView: 1.2,
                                spaceBetween: 16,
                            },
                            992: {
                                slidesPerView: 3,
                                spaceBetween: 24,
                            },
                        },
                        navigation: {
                            nextEl: nextEl,
                            prevEl: prevEl,
                        },
                        pagination: {
                            el: section.parentElement.querySelector(
                                '.swiper-pagination-contact-box'
                            ),
                            type: 'bullets',
                            bulletClass: 'bottom-pagination__bullet',
                            bulletActiveClass: 'active',
                        },
                    });
                    slidersArray.push(carousel);
                }
            }
        });
    }
}

InitContactBox();
